import { motion } from "framer-motion";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import SocialIcons from "../components/SocialIcons";
import aboutMeImg from "../images/aboutme.jpg";
import resume from "../pages/about/hrithvik-gadhiya-resume-feb-2025.pdf";

const AboutMe = ({ name, email, location, availability, brand }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [showExperience, setShowExperience] = useState(false);

  const handleDownload = () => {
    window.open(resume, "_blank");
  };

  return (
    <>
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-4"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <img src={aboutMeImg} alt={name} />
          </motion.div>
          <motion.div
            className="personalInfo col-12 col-lg-8"
            ref={ref}
            initial={{ x: "10vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <div className="contentContainer">
              <h4>Nice to meet you</h4>
              <h5>
                I am a Full Stack Software Engineer crafting innovative
                technical solutions.
              </h5>
              <div className="contentDescription">
                <p>{brand}</p>
              </div>
              <div className="infoContainer">
                <div className="row">
                  <div className="col-12 col-md-6 info">
                    <span>Name:</span>
                    <p>{name}</p>
                  </div>
                  <div className="col-12 col-md-6 info">
                    <span>Email:</span>
                    <p>
                      <a href={`mailto:${email}`}>{email}</a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 info">
                    <span>Location:</span>
                    <p>{location}</p>
                  </div>
                  <div className="col-12 col-md-6 info">
                    <span>Availability:</span>
                    <p>{availability}</p>
                  </div>
                </div>
              </div>
              <div className="buttonContainer">
                {/* <button
									className="btn downloadCV experience_btn"
									href="#experience"
									onClick={() => {
										setShowExperience(!showExperience);
										if (!showExperience) {
											console.log("scrolling into exp");
											const experienceSection =
												document.getElementById("experience");
											experienceSection.scrollIntoView({ behavior: "smooth" });
										}
									}}
								>
									{showExperience ? "Hide My Experience" : "View My Experience"}
								</button> */}
                <button
                  className="btn downloadCV"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  {"View Resume"}
                </button>
                <SocialIcons />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {showExperience && (
        <div class="timeline-container" id="experience">
          <div className="row">
            <div class="content left-content">
              <motion.div
                className="timeline-content"
                ref={ref}
                initial={{ x: "-10vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <h2>Full Stack Developer</h2>
                <h4>
                  Stream Data Systems{" "}
                  <span style={{ float: "right" }}>Jan 2023 - Present</span>
                </h4>
                <p>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      Developed an Android app using React Native and C# for
                      real-time control of phone cameras by dispatch system
                      users through our web app via WebSocket connections.{" "}
                    </li>
                    <li>
                      Built a C# module for distributed file storage and
                      management, handling files for numerous daily patrols.{" "}
                    </li>
                    <li>
                      Designed and implemented Azure CI/CD pipelines to automate
                      software builds, testing, and deployment, streamlining
                      workflows and enabling faster release of software
                      products.{" "}
                    </li>
                    <li>
                      Performed server upgrades and migrations for clients,
                      improving system performance and ensuring uninterrupted
                      operations.
                    </li>
                  </ul>
                </p>
              </motion.div>
            </div>
            <div class="column-divider"></div>
            <div class="content right-content"></div>
          </div>
          <div className="row">
            <div class="content left-content"></div>
            <div class="column-divider"></div>
            <div class="content right-content">
              <motion.div
                className="timeline-content"
                ref={ref}
                initial={{ x: "10vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <h2>Full Stack Developer Intern</h2>
                <h4>
                  Stream Data Systems{" "}
                  <span style={{ float: "right" }}>Jan 2022 - Dec 2022</span>
                </h4>
                <p>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      Built and integrated interactive reporting software for
                      CANdispatch - our security dispatching software using
                      React and C#.
                    </li>
                    <li>
                      Upgraded the legacy scheduling module of our dispatch
                      system by introducing new features using C# and modern
                      React UI, resulting in a significant 2x improvement in
                      operational efficiency and user experience.
                    </li>
                    <li>
                      Built a form generator based on React and Material-UI used
                      across client websites. This resulted in reduction of
                      development time on complex forms by 75%.
                    </li>
                    <li>
                      Revamped outdated UIs by implementing modern and efficient
                      UIs using React, resulting in a substantial 75% reduction
                      in average load times.
                    </li>
                  </ul>
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutMe;
